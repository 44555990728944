import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useGetFoodSafetyTestQuery } from 'api/products';
import { toSnakecaseKeys } from 'utils/helper/ObjectKeyConverter';
import { hasCompanyPermissions } from 'utils/helper/UserRoleValidation';
import { labelWithHints } from 'utils/helper/LabelWithHints';
import { localizedSelectedObject } from 'utils/helper/selectBoxUtils';
import OrganicCertificateDocuments from 'components/BiddingProcess/CreateListing/FoodSafety/OrganicCertificate';
import Button from 'components/BaseComponents/Button';
import {
  BRC_CERT,
  brcLevelOptions,
  containerLoads,
  DRIED_NUT_IN_SHELL_PRODUCT,
  foodCertificates,
  nutGrades,
  nutStyles,
  paymentOptions,
  priceTypes,
  regions,
  religionFields,
  seasons,
} from 'utils/constants/product';
import CountrySelect from 'components/BaseComponents/CountrySelect';
import DatePicker from 'components/BaseComponents/DatePicker';
import SelectBox from 'components/BaseComponents/SelectBox';
import Input from 'components/BaseComponents/Input';
import CheckBox from 'components/BaseComponents/CheckBox';
import { OFFER, ORDER } from 'utils/constants/offerListings';
import SubmitButtons from '../StepperButtons/SubmitButtons';
import './styles.scss';

const KernelProductDetails = ({
  currentStepIndex,
  onPrevButtonClick,
  onProductSave,
  productDetails,
  companyDetails,
  category,
}) => {
  const { t } = useTranslation();
  const [isBestBeforeOpen, setIsBestBeforeOpen] = useState(false);
  const [isESDDateOpen, setIsESDDateOpen] = useState(false);

  const {
    data: foodSafetyTestDetails = {},
  } = useGetFoodSafetyTestQuery({ productId: productDetails?.id }, { skip: !productDetails?.id });

  const isNewProduct = !productDetails;
  const categoryOffer = category === OFFER;
  const categoryOrder = category === ORDER;
  const canCreateNewDriedNutInShell = isNewProduct && (categoryOrder || hasCompanyPermissions(DRIED_NUT_IN_SHELL_PRODUCT, companyDetails));

  const allowedReligionFields = religionFields.filter((field) => (
    categoryOrder || (productDetails && productDetails[field]) || companyDetails[`${field}_certificate`]
  ));

  const initValue = useMemo(() => {
    if (isNewProduct) {
      return null;
    }

    const priceType = localizedSelectedObject(
      `shared.product.price_type.${productDetails.priceType}`,
      productDetails.priceType,
    );

    const nutGrade = localizedSelectedObject(
      `shared.product.nut_grade.${productDetails.nutGrade}`,
      productDetails.nutGrade,
    );
    const nutStyle = localizedSelectedObject(
      `shared.product.nut_style.${productDetails.nutStyle}`,
      productDetails.nutStyle,
    );
    const regionOfOrigin = localizedSelectedObject(
      `shared.product.regions.${productDetails.regionOfOrigin}`,
      productDetails.regionOfOrigin,
    );
    const seasonHarvested = localizedSelectedObject(
      productDetails.seasonHarvested,
      productDetails.seasonHarvested,
    );
    const seasonProcessed = localizedSelectedObject(
      productDetails.seasonProcessed,
      productDetails.seasonProcessed,
    );
    const minimumFoodCertificate = localizedSelectedObject(
      `shared.product.food_certificates.${productDetails.minimumFoodCertificate}`,
      productDetails?.minimumFoodCertificate,
    );
    const brcLevel = localizedSelectedObject(
      `shared.product.brc_levels.${productDetails.brcLevel}`,
      productDetails?.brcLevel,
    );
    const containerLoad = localizedSelectedObject(
      `shared.product.container_load.${productDetails.containerLoad}`,
      productDetails.containerLoad,
    );
    const paymentOption = localizedSelectedObject(
      `shared.product.payment_options.${productDetails.paymentOptions}`,
      productDetails.paymentOptions,
    );

    const  productFoodSafetyAttributes = {
      product_food_safety_listing_organic_certificates: foodSafetyTestDetails.listing_organic_certificates,
      product_food_safety_organic: foodSafetyTestDetails.organic,
      product_food_safety_id: foodSafetyTestDetails.id,
    }

    const productAttributes = toSnakecaseKeys({
      ...productDetails,
      priceType,
      nutGrade,
      nutStyle,
      seasonHarvested,
      seasonProcessed,
      regionOfOrigin,
      minimumFoodCertificate,
      containerLoad,
      brcLevel,
      paymentOptions: paymentOption,
    });

    return {
      ...productAttributes,
      ...productFoodSafetyAttributes,
    }
  }, [productDetails, foodSafetyTestDetails]);

  const [brcLevelsVisible, setBrcLevelsVisible] = useState(
    productDetails?.minimumFoodCertificate === BRC_CERT,
  );
  const toggleBrcLevelsVisible = (e) => setBrcLevelsVisible(e?.value === BRC_CERT);


  const submitForm = (values) => onProductSave({...values, product_food_safety_id: foodSafetyTestDetails.productId });

  return (
    <div className="product-details-form">
      <div className="flex gap-32px align-center">
        <h2 className="margin-l-12px">
          {t(`offer_creation.product_details.kernel.title.${category}`)}
        </h2>
        {canCreateNewDriedNutInShell && (
          <Button
            label="DNIS"
            type="success"
            onClick={() => {
              localStorage.setItem('nutsTab', DRIED_NUT_IN_SHELL_PRODUCT);
              window.location.reload();
            }}
          />
        )}
      </div>
      <Form
        onSubmit={submitForm}
        initialValues={initValue}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <SubmitButtons
              isSaveAsDraftVisible={false}
              isSubmitButtonDisabled={submitting}
              currentStepIndex={currentStepIndex}
              onPrevButtonClick={onPrevButtonClick}
            />
            <div className="product-details-form__fields">
              <h3 className="product-details-form__title margin-none">
                {t(`offer_creation.product_details.form.title.${category}`)}
              </h3>
              <p className="product-details-form__section-title">
                {t('offer_creation.product_details.form.price_section')}
              </p>
              <div className="flex gap-20px padding-t-16px">
                <Field name="offer_volume">
                  {({ input, meta }) => (
                    <Input
                      label={t(`offer_creation.product_details.form.volume.label.${category}`)}
                      placeholder={t('offer_creation.product_details.form.volume.placeholder')}
                      required={true}
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      onChange={input.onChange}
                      type="number"
                      className="product-details-form__offer-volume"
                    />
                  )}
                </Field>
                <Field name="offer_price">
                  {({ input, meta }) => (
                    <Input
                      label={t(`offer_creation.product_details.form.price.label.${category}`)}
                      placeholder={t('offer_creation.product_details.form.price.placeholder')}
                      required={true}
                      className="product-details-form__offer-price"
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      type="number"
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>
              <div className="flex gap-20px padding-t-16px">
                <Field name="boxes_quantity">
                  {({ input, meta }) => (
                    <Input
                      label={t(`offer_creation.product_details.form.boxes_quantity.label`)}
                      placeholder={t(
                        'offer_creation.product_details.form.boxes_quantity.placeholder',
                      )}
                      required={false}
                      className="product-details-form__boxes-quantity"
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      type="number"
                      onChange={input.onChange}
                    />
                  )}
                </Field>
                {categoryOrder && (
                  <Field name="price_type">
                    {({ input, meta }) => (
                      <SelectBox
                        label={t('offer_creation.product_details.form.price_type.label')}
                        placeholderText={t(
                          'offer_creation.product_details.form.price_type.placeholder',
                        )}
                        options={priceTypes.slice(-2)}
                        value={input.value}
                        errorMsg={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                        touched={meta.touched}
                        onChange={input.onChange}
                        required={categoryOrder}
                        isSearchable={false}
                        width="full"
                        className="product-details-form__price-type"
                      />
                    )}
                  </Field>
                )}
              </div>
              <p className="product-details-form__section-title margin-t-60px">
                {t('offer_creation.product_details.form.details_section')}
              </p>
              <div className="flex gap-20px padding-t-16px">
                <Field name="nut_grade">
                  {({ input, meta }) => (
                    <SelectBox
                      label={t('offer_creation.product_details.form.nut_grade.label')}
                      placeholderText={t(
                        'offer_creation.product_details.form.nut_grade.placeholder',
                      )}
                      options={nutGrades}
                      value={input.value}
                      errorMsg={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      onChange={input.onChange}
                      required={categoryOffer}
                      isSearchable={false}
                      width="full"
                      className="product-details-form__offer-volume"
                    />
                  )}
                </Field>
                <Field name="nut_style">
                  {({ input, meta }) => (
                    <SelectBox
                      label={labelWithHints(
                        t('offer_creation.product_details.form.nut_style.label'),
                        t('offer_creation.product_details.form.nut_style.tooltip'),
                      )}
                      placeholderText={t(
                        'offer_creation.product_details.form.nut_style.placeholder',
                      )}
                      options={nutStyles}
                      value={input.value}
                      errorMsg={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      onChange={input.onChange}
                      onCreateOption={(createdOption) =>
                        input.onChange({ label: createdOption, value: createdOption })
                      }
                      isCreatable={true}
                      required={true}
                      width="full"
                      className="product-details-form__offer-volume"
                    />
                  )}
                </Field>
              </div>
              <div className="flex gap-20px margin-t-20px">
                <Field name="season_harvested">
                  {({ input, meta }) => (
                    <SelectBox
                      label={t('offer_creation.product_details.form.season_harvested.label')}
                      placeholderText={t(
                        'offer_creation.product_details.form.season_harvested.placeholder',
                      )}
                      options={seasons}
                      value={input.value}
                      errorMsg={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      onChange={input.onChange}
                      required={categoryOffer}
                      width="full"
                      className="product-details-form__offer-volume"
                    />
                  )}
                </Field>
                <Field name="season_processed">
                  {({ input, meta }) => (
                    <SelectBox
                      label={t('offer_creation.product_details.form.season_processed.label')}
                      placeholderText={t(
                        'offer_creation.product_details.form.season_processed.placeholder',
                      )}
                      options={seasons}
                      value={input.value}
                      errorMsg={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      onChange={input.onChange}
                      required={categoryOffer}
                      width="full"
                      className="product-details-form__offer-volume"
                    />
                  )}
                </Field>
              </div>
              <div className="flex gap-20px margin-t-20px">
                <Field name="region_of_origin">
                  {({ input, meta }) => (
                    <SelectBox
                      label={t('offer_creation.product_details.form.region.label')}
                      placeholderText={t('offer_creation.product_details.form.region.placeholder')}
                      options={regions}
                      value={input.value}
                      errorMsg={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      onChange={input.onChange}
                      width="full"
                      className="product-details-form__offer-volume"
                    />
                  )}
                </Field>
                <Field name="country_of_origin">
                  {({ input, meta }) => (
                    <CountrySelect
                      label={t('offer_creation.product_details.form.country.label')}
                      tooltip={t('offer_creation.product_details.form.country.tooltip')}
                      placeholder={t('offer_creation.product_details.form.country.placeholder')}
                      required={categoryOffer}
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta?.touched}
                      country={input.value}
                      onChange={(code) => input.onChange(code)}
                    />
                  )}
                </Field>
              </div>
              {categoryOrder && (
                <div className="flex gap-20px margin-t-20px">
                  <Field name="destination_country">
                    {({ input, meta }) => (
                      <CountrySelect
                        label={t('offer_creation.product_details.form.destination_country.label')}
                        placeholder={t('offer_creation.product_details.form.destination_country.placeholder')}
                        error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                        touched={meta?.touched}
                        country={input.value}
                        onChange={(code) => input.onChange(code)}
                      />
                    )}
                  </Field>
                </div>
              )}
              <div className="flex gap-20px margin-t-20px">
                <Field name="minimum_food_certificate" onChange={toggleBrcLevelsVisible}>
                  {({ input, meta, onChange }) => (
                    <SelectBox
                      label={t('offer_creation.product_details.form.food_certificate.label')}
                      placeholderText={t(
                        'offer_creation.product_details.form.food_certificate.placeholder',
                      )}
                      options={foodCertificates}
                      value={input.value}
                      errorMsg={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      onChange={(e) => {
                        onChange(e);
                        input.onChange(e);
                      }}
                      required={categoryOffer}
                      width="full"
                      className="product-details-form__offer-volume"
                    />
                  )}
                </Field>
                <Field name="best_before_date">
                  {({ input, meta }) => (
                    <DatePicker
                      label={t('offer_creation.product_details.form.best_before.label')}
                      placeholder={t('offer_creation.product_details.form.best_before.placeholder')}
                      id="bestBeforeDate"
                      focused={isBestBeforeOpen}
                      onFocusChange={(isFocued) => setIsBestBeforeOpen(isFocued)}
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      date={input.value}
                      onChange={input.onChange}
                      required={true}
                      pastYearsCount={0}
                      className="product-details-form__offer-price"
                    />
                  )}
                </Field>
              </div>
              <div className="product-details-form__brc_levels margin-t-20px">
                {brcLevelsVisible && (
                  <Field name="brc_level">
                    {({ input, meta }) => (
                      <SelectBox
                        label={t('offer_creation.product_details.form.brc_level.label')}
                        placeholderText={t(
                          'offer_creation.product_details.form.brc_level.placeholder',
                        )}
                        options={brcLevelOptions}
                        value={input.value}
                        errorMsg={
                          !meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)
                        }
                        touched={meta.touched}
                        onChange={input.onChange}
                        className="product-details-form__offer-volume"
                      />
                    )}
                  </Field>
                )}
              </div>
              {allowedReligionFields.length > 0 && (
                <div className="product-details-form__religion margin-t-20px">
                  <div className="product-details-form__section-title">
                    {t("offer_creation.product_details.form.religion.label")}
                  </div>
                  <div className="product-details-form__religion-container">
                    {allowedReligionFields.map((field) => (
                      <Field name={field} type="checkbox" key={field}>
                        {({ input, meta }) => (
                          <CheckBox
                            className="product-details-form__religion-label"
                            label={t(`shared.product.religion.${field}`)}
                            isChecked={input.checked}
                            onChange={input.onChange}
                            hintText={meta.submitError}
                          />
                        )}
                      </Field>
                    ))}
                  </div>
                </div>
              )}
              <p className="product-details-form__section-title margin-t-60px">
                {t('offer_creation.product_details.form.logistics_section')}
              </p>
              <div className="flex gap-20px margin-t-20px">
                <Field name="container_load">
                  {({ input, meta }) => (
                    <SelectBox
                      label={t('offer_creation.product_details.form.container_load.label')}
                      placeholderText={t(
                        'offer_creation.product_details.form.container_load.placeholder',
                      )}
                      options={containerLoads}
                      value={input.value}
                      errorMsg={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      onChange={input.onChange}
                      required={categoryOffer}
                      width="full"
                      className="product-details-form__offer-volume"
                    />
                  )}
                </Field>
                <Field name="estimated_shipping_date">
                  {({ input, meta }) => (
                    <DatePicker
                      label={t('offer_creation.product_details.form.esd.label')}
                      placeholder={t('offer_creation.product_details.form.esd.placeholder')}
                      id="estimatedShippingDate"
                      focused={isESDDateOpen}
                      onFocusChange={(isFocued) => setIsESDDateOpen(isFocued)}
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      date={input.value}
                      onChange={input.onChange}
                      required={categoryOffer}
                      pastYearsCount={0}
                      openDirection="up"
                      className="product-details-form__offer-price"
                    />
                  )}
                </Field>
              </div>
              <div className="product-details-form__logisitcs margin-t-20px">
                {categoryOffer && (
                  <Field name="payment_options">
                    {({ input, meta }) => (
                      <SelectBox
                        label={t('offer_creation.product_details.form.payment.label')}
                        placeholderText={t(
                          'offer_creation.product_details.form.payment.placeholder',
                        )}
                        options={paymentOptions}
                        value={input.value}
                        errorMsg={
                          !meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)
                        }
                        touched={meta.touched}
                        onChange={input.onChange}
                        onCreateOption={(createdOption) =>
                          input.onChange({ label: createdOption, value: createdOption })
                        }
                        required={true}
                        isCreatable={true}
                        menuPlacement="top"
                        width="full"
                        className="product-details-form__offer-volume"
                      />
                    )}
                  </Field>
                )}
                <Field name="consignment_identifier">
                  {({ input, meta }) => (
                    <Input
                      label={labelWithHints(
                        t('offer_creation.product_details.form.identifier.label'),
                        t('offer_creation.product_details.form.identifier.tooltip'),
                      )}
                      placeholder={t('offer_creation.product_details.form.identifier.placeholder')}
                      className="product-details-form__offer-volume"
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <p className="product-details-form__section-title margin-t-60px">
                {t('offer_creation.product_details.form.order_food_safety_certifications.title')}
              </p>
              {categoryOrder && (
                <div className="product-details-form__religion-container">
                  <Field name="gacc_certificate" type="checkbox">
                    {({ input, meta }) => (
                      <CheckBox
                        className="product-details-form__religion-label"
                        label={t("shared.product.order_food_safety_certifications.gacc_certificate")}
                        isChecked={input.checked}
                        onChange={input.onChange}
                        hintText={meta.submitError}
                      />
                    )}
                  </Field>
                  <Field name="organic" type="checkbox">
                    {({ input, meta }) => (
                      <CheckBox
                        className="product-details-form__religion-label"
                        label={t("shared.product.order_food_safety_certifications.organic")}
                        isChecked={input.checked}
                        onChange={input.onChange}
                        hintText={meta.submitError}
                      />
                    )}
                  </Field>
                  <Field name="pasteurized" type="checkbox">
                    {({ input, meta }) => (
                      <CheckBox
                        className="product-details-form__religion-label"
                        label={t("shared.product.order_food_safety_certifications.pasteurized")}
                        isChecked={input.checked}
                        onChange={input.onChange}
                        hintText={meta.submitError}
                      />
                    )}
                  </Field>
                </div>
              )}

              {categoryOffer && <OrganicCertificateDocuments />}
            </div>
          </form>
        )}
      />
    </div>
  );
};

KernelProductDetails.defaultProps = {
  currentStepIndex: 0,
  onPrevButtonClick: () => {},
  onProductSave: () => {},
  productDetails: null,
  category: null,
};

KernelProductDetails.propTypes = {
  currentStepIndex: PropTypes.number,
  onPrevButtonClick: PropTypes.func,
  onProductSave: PropTypes.func,
  productDetails: PropTypes.shape({
    id: PropTypes.number,
    offerVolume: PropTypes.string,
    priceType: PropTypes.string,
    nutGrade: PropTypes.string,
    nutStyle: PropTypes.string,
    seasonHarvested: PropTypes.string,
    seasonProcessed: PropTypes.string,
    regionOfOrigin: PropTypes.string,
    countryOfOrigin: PropTypes.string,
    minimumFoodCertificate: PropTypes.string,
    bestBeforeDate: PropTypes.string,
    containerLoad: PropTypes.string,
    estimatedShippingDate: PropTypes.string,
    paymentOptions: PropTypes.string,
    consignmentIdentifier: PropTypes.string,
    brcLevel: PropTypes.number,
    halaal: PropTypes.bool,
    kosher: PropTypes.bool,
    gacc_certificate: PropTypes.bool,
    organic: PropTypes.bool,
    pasteurized: PropTypes.bool,
  }),
  companyDetails: PropTypes.shape({
    company_permissions: PropTypes.shape({
      trade_kernel: PropTypes.bool,
      trade_dried_nut_in_shell: PropTypes.bool,
    }),
  }).isRequired,
  category: PropTypes.string,
};

export default KernelProductDetails;
