import React, { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import PropTypes from 'prop-types';
import {
  useGetCompanyBusinessContactsQuery,
  useUpdateCompanyBusinessContactsMutation,
} from 'api/companies';
import { useDraftMutation, useGetDraftQuery } from 'api/users';
import { downloadFile, generateBusinessContactBody, generateProfileDraftBody } from 'utils/helper/RequestBody';
import { handleServerValidationErrors } from 'utils/helper/Validations';
import {
  FOOD_SAFETY_FORM_KEY,
  MAX_NUMBER_OF_DIRECTOR,
  SINGLE_FILE_UPLOAD_COUNT,
  ZERO_FILE_UPLOAD_COUNT,
} from 'utils/constants/portals';
import { processPortalFormSuccess } from 'utils/helper/FormHandlers';
import { mergeFileResponce, mergeResponce } from 'utils/helper/ObjectKeyConverter';
import { errorToastConfig } from 'utils/constants/ToastConfig';
import Icon from 'components/BaseComponents/Icon';
import Button from 'components/BaseComponents/Button';
import Toastify from 'components/Shared/ToastNotification/Toastify';
import DirectorForm from './DirectorForm';
import CompanyProxyForm from './CompanyProxyForm';
import TradeReference from './TradeReference';
import './style.scss';

const BusinessContacts = ({ companyId, isEditable, userDetails, toastRef }) => {
  const draftName = "BusinessContacts"
  const [removedDirectors, setRemovedDirectors] = useState([]);

  const { t } = useTranslation();
  const { data: userBusinessContacts } = useGetCompanyBusinessContactsQuery(
    { id: companyId },
    { skip: !companyId },
  );

  const { data: businessContactsDraft = {} } = useGetDraftQuery({ id: userDetails?.id, draftName });

  const businessContactsDraftAttachments = {}
  if(businessContactsDraft?.attachments) {
    Object.keys(businessContactsDraft.attachments).forEach((name) => {
      const attachment = businessContactsDraft.attachments[name];

      downloadFile(attachment?.url, attachment?.name).then((file) => {
        businessContactsDraftAttachments[name] = file;
      });
    });
  }

  const directorsIsEmpty = JSON.stringify(userBusinessContacts?.directors) === "[{}]";
  const directorsToIterate = (directorsIsEmpty ? businessContactsDraft?.payload?.directors : userBusinessContacts?.directors) || [{}];

  const isFormSaved = !!userBusinessContacts?.authorized_person?.identification_number;
  const draftValues = useMemo(()=> ({
    directors: directorsToIterate?.map((director, i) => mergeFileResponce(
      mergeResponce(director, (businessContactsDraft?.payload?.directors || [])[i]),
      businessContactsDraft?.attachments,
      `director${i}_`,
    )),
    trade_references: userBusinessContacts?.trade_references.map((ref, i) => (
      mergeResponce(ref, (businessContactsDraft?.payload?.trade_references || [])[i])
    )),
    authorized_person: mergeFileResponce(
      mergeResponce(userBusinessContacts?.authorized_person, businessContactsDraft?.payload?.authorized_person),
      businessContactsDraft?.attachments,
    ),
  }), [userBusinessContacts, businessContactsDraft]);
  const initialValues = isFormSaved ? userBusinessContacts : draftValues;

  const companyProxyDocFiles = {
    companyResolution: initialValues?.authorized_person?.company_resolution,
    idDocument: initialValues?.authorized_person?.id_document,
    signedLetter: initialValues?.authorized_person?.signed_letter,
  };

  const [updateDraft] = useDraftMutation();
  const [updateCompanyBusinessContacts] = useUpdateCompanyBusinessContactsMutation();

  const handleDirectorRemove = (fields, directorIndex) => {
    fields.remove(directorIndex);

    const directors = fields.value;
    const removedDirector = directors[directorIndex];

    if (removedDirector && removedDirector?.id) {
      setRemovedDirectors([...removedDirectors, { ...removedDirector, _destroy: true }]);
    }
  };

  const handleBusinessContactUpdate = (params) => {
    const requestBody = generateBusinessContactBody(
      params,
      removedDirectors,
      businessContactsDraftAttachments,
    );

    return updateCompanyBusinessContacts({ companyId, payload: requestBody })
      .unwrap()
      .then(() => processPortalFormSuccess(toastRef, FOOD_SAFETY_FORM_KEY))
      .catch(({ data: { message }, status }) =>
        handleServerValidationErrors(toastRef, status, message),
      );
  };

  const totalDirectorDocuments = (formData, directorIndex) => {
    const {
      values: { directors },
    } = formData.getState();

    const { id_document: uploadedDocument } = directors[directorIndex];

    if (uploadedDocument) {
      return SINGLE_FILE_UPLOAD_COUNT;
    }

    return ZERO_FILE_UPLOAD_COUNT;
  };

  const totalCompanyProxyDocuments = (formData) => {
    const {
      values: { authorized_person: authorizedPerson },
    } = formData.getState();

    const filesToCheck = [
      authorizedPerson?.company_resolution,
      authorizedPerson?.id_document,
      authorizedPerson?.signed_letter,
    ];

    const totalFiles = filesToCheck.reduce((fileCount, fileName) => {
      if (fileName) {
        return fileCount + 1;
      }

      return fileCount;
    }, ZERO_FILE_UPLOAD_COUNT);

    return totalFiles;
  };

  const saveAsDraft = (values) => {
    const additionalFiles = [
      ...(values.directors?.map((director, i) => ({ name: `director_${i}_id_document`, file: director?.id_document })) || []),
      { name: 'company_resolution', file: values?.authorized_person?.company_resolution },
      { name: 'id_document', file: values?.authorized_person?.id_document },
      { name: 'signed_letter', file: values?.authorized_person?.signed_letter },
    ]
    const payload = generateProfileDraftBody(values, draftName, additionalFiles);

    updateDraft({ id: userDetails.id, payload })
      .unwrap()
      .then(() => processPortalFormSuccess(toastRef, FOOD_SAFETY_FORM_KEY))
      .catch(({ data: { errors }, status }) =>
        handleServerValidationErrors(toastRef, status, errors),
      );
  }

  return (
    <div className="business-contacts padding-t-32px">
      <h3 className="business-contacts__title padding-x-24px">
        {t('profile.business_contacts.title')}
      </h3>
      <Form
        onSubmit={handleBusinessContactUpdate}
        initialValues={initialValues}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, form, submitting, hasValidationErrors, values }) => (
          <form
            onSubmit={(event) => {
              if (hasValidationErrors) {
                toastRef?.current?.notify(<Toastify type="error" />, errorToastConfig);
              }
              handleSubmit(event);
            }}
            className="padding-y-32px"
          >
            <fieldset disabled={!isEditable}>
              <FieldArray name="directors">
                {({ fields }) => (
                  <Fragment>
                    {fields.map((name, index) => (
                      <DirectorForm
                        key={name}
                        directorIndex={index + 1}
                        idDirector={`${name}.id`}
                        firstName={`${name}.first_name`}
                        lastName={`${name}.last_name`}
                        identificationNumber={`${name}.identification_number`}
                        idDocuments={`${name}.id_document`}
                        totalUploadedDocument={totalDirectorDocuments(form, index)}
                        totalAddedDirector={fields.length}
                        onAddDirector={() => fields.push({})}
                        onRemoveDirector={() => handleDirectorRemove(fields, index)}
                        isEditable={isEditable}
                        directorFile={initialValues?.directors[index]?.id_document}
                      />
                    ))}
                    {fields.length < MAX_NUMBER_OF_DIRECTOR && isEditable && (
                      <Button
                        submitType="button"
                        className="flex items-center gap-8px margin-b-40px padding-x-24px business-contacts__add-fields"
                        label={t('profile.business_contacts.form.add_director')}
                        icon={<Icon name="add" color="success" />}
                        onClick={() => fields.push({})}
                      />
                    )}
                  </Fragment>
                )}
              </FieldArray>
              <CompanyProxyForm
                totalUploadedDocument={totalCompanyProxyDocuments(form)}
                isEditable={isEditable}
                files={companyProxyDocFiles}
                form={form}
              />
              <FieldArray name="trade_references">
                {({ fields }) => (
                  <Fragment>
                    {fields.map((name, index) => (
                      <TradeReference
                        key={name}
                        tradeReferenceIndex={index + 1}
                        idTradeReference={`${name}.id`}
                        reference={`${name}.reference`}
                        contactPersonName={`${name}.contact_person_name`}
                        phoneNumber={`${name}.phone_number`}
                        email={`${name}.email`}
                        totalAddedTradeReference={fields.length}
                        isEditable={isEditable}
                      />
                    ))}
                  </Fragment>
                )}
              </FieldArray>

              {isEditable && (
                <Button
                  className="business-contacts__submit-btn margin-t-64px margin-l-24px"
                  label={t('profile.submit')}
                  submitType="submit"
                  type="success"
                  disabled={submitting}
                />
              )}
              {isEditable && !isFormSaved && (
                <Button
                  className="business-contacts__draft-btn margin-t-64px margin-l-8px"
                  label="Save as draft"
                  onClick={() => saveAsDraft(values)}
                  type="default"
                  disabled={submitting}
                />
              )}
            </fieldset>
          </form>
        )}
      />
    </div>
  );
};

BusinessContacts.defaultProps = {
  companyId: null,
  isEditable: true,
  toastRef: null,
};

BusinessContacts.propTypes = {
  companyId: PropTypes.number,
  isEditable: PropTypes.bool,
  toastRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  userDetails: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
  }).isRequired,
};

export default BusinessContacts;
