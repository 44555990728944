import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { processPortalFormSuccess } from 'utils/helper/FormHandlers';
import {
  handleServerValidationErrors,
  validateOptionalPortalDocuments,
} from 'utils/helper/Validations';
import Button from 'components/BaseComponents/Button';
import {
  useGetFoodSafetyDocumentsQuery,
  useUpdateFoodSafetyDocumentsMutation,
  useDeleteFoodSafetyDocumentsMutation,
} from 'api/FoodSafety';
import Toastify from 'components/Shared/ToastNotification/Toastify';
import { useDraftMutation, useGetCurrentUserQuery, useGetDraftQuery } from 'api/users';
import ChooseFile from 'components/Shared/ChooseFile';
import { generateFoodSafetyBody, generateProfileDraftBody } from 'utils/helper/RequestBody';
import { labelWithHints } from 'utils/helper/LabelWithHints';
import { errorToastConfig } from 'utils/constants/ToastConfig';
import { hasAdminRole } from 'utils/helper/UserRoleValidation';
import { mergeFileResponce } from 'utils/helper/ObjectKeyConverter';
import './style.scss';

function FoodSafetyForm({ companyId, isEditable, userDetails, toastRef }) {
  const draftName = "FoodSafetyForm"
  const { t } = useTranslation();
  const { data: user } = useGetCurrentUserQuery();
  let { data: foodSafetyDocuments } = useGetFoodSafetyDocumentsQuery(
    { id: companyId },
    {
      skip: !companyId,
    },
  );

  const { data: foodSafetyDraft = {} } = useGetDraftQuery({ id: userDetails?.id, draftName });
  const isFormSaved = !!(
    foodSafetyDocuments?.safety_document?.url ||
    foodSafetyDocuments?.ppecb_company_certificate?.url ||
    foodSafetyDocuments?.organic_certificate?.url
  );

  const [updateDraft] = useDraftMutation();

  if (!foodSafetyDocuments) {
    foodSafetyDocuments = {
      safety_document: null,
      safety_process_diagram: null,
      safety_data_sheet: null,
      total_diet_study: null,
      regulatory_approvals: null,
      ppecb_company_certificate: null,
      global_gap_company_certificate: null,
      gacc_company_certificate: null,
      mrl_company_results: null,
      esg_company_certificate: null,
      organic_certificate: null,
    };
  }
  const initialValues = isFormSaved ? foodSafetyDocuments : mergeFileResponce(foodSafetyDocuments, foodSafetyDraft.attachments);

  const canDeleteAttachemt = hasAdminRole(user) || isEditable;
  const disableDelete = !canDeleteAttachemt;

  const canDeletePpecb = !foodSafetyDocuments?.ppecb_company_certificate?.url || canDeleteAttachemt; // disable if uploaded
  const canDeleteGlobalGap =
    !foodSafetyDocuments?.global_gap_company_certificate?.url || canDeleteAttachemt; // disable if uploaded
  const canDeleteGacc = !foodSafetyDocuments?.gacc_company_certificate?.url || canDeleteAttachemt; // disable if uploaded
  const canDeleteMrl = !foodSafetyDocuments?.mrl_company_results?.url || canDeleteAttachemt; // disable if uploaded
  const canDeleteEsg = !foodSafetyDocuments?.esg_company_certificate?.url || canDeleteAttachemt; // disable if uploaded
  const canDeleteOrganic = !foodSafetyDocuments?.organic_certificate?.url || canDeleteAttachemt; // disable if uploaded

  const disableDeleteOrganic = !canDeleteOrganic;
  const disableDeletePpecb = !canDeletePpecb;
  const disableDeleteGlobalGap = !canDeleteGlobalGap;
  const disableDeleteGacc = !canDeleteGacc;
  const disableDeleteMrl = !canDeleteMrl;
  const disableDeleteEsg = !canDeleteEsg;

  const [updateFoodSafetyDocuments] = useUpdateFoodSafetyDocumentsMutation();
  const [baseError, setBaseError] = useState('');

  const handleFormSubmit = async (values) => {
    if (!values || !Object.values(values).some((v) => v)) {
      return null;
    }
    const requestBody = await generateFoodSafetyBody({ ...values, id: foodSafetyDocuments?.id });

    return updateFoodSafetyDocuments({ companyId, payload: requestBody })
      .unwrap()
      .then(() => {
        setBaseError('');
        processPortalFormSuccess(toastRef);
      })
      .catch(({ data: { message }, status }) => {
        setBaseError(message?.foodSafetyDocument?.base);
        handleServerValidationErrors(toastRef, status, message);
      });
  };

  const [deleteAttachment] = useDeleteFoodSafetyDocumentsMutation();

  const handleDelete = (input) => {
    const attachment = input.value;

    const attachmentId = attachment.id;
    if (!attachmentId) {
      return;
    }

    fetch(attachment.url)
      .then((response) => {
        const contentType = response.headers.get('Content-Type');
        return response.blob().then((blob) => ({ blob, contentType }));
      })
      .then(({ blob, contentType }) => {
        const url = window.URL.createObjectURL(new Blob([blob], { type: contentType }));
        const link = document.createElement('a');
        link.href = url;
        link.download = attachment.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error fetching the file:', error);
      });
    deleteAttachment({ companyId, attachmentId });
  };

  const saveAsDraft = (values) => {
    const payload = generateProfileDraftBody(values, draftName);

    updateDraft({ id: userDetails.id, payload })
      .unwrap()
      .then(() => {
        setBaseError('');
        processPortalFormSuccess(toastRef);
      })
      .catch(({ data: { errors }, status }) =>
        handleServerValidationErrors(toastRef, status, errors),
      );
  }

  return (
    <Form
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, hasValidationErrors, values }) => (
        <form
          onSubmit={(event) => {
            if (hasValidationErrors) {
              toastRef?.current?.notify(<Toastify type="error" />, errorToastConfig);
            }
            handleSubmit(event);
          }}
          className="food-safety"
        >
          <fieldset>
            <h3 className="food-safety__title">
              {labelWithHints(
                t('profile.food_safety.heading'),
                t('shared.form_validation.food_safety.tooltip'),
              )}
            </h3>
            {baseError && <div className="food-safety__base-error">{baseError}</div>}
            <div className="padding-x-12px">
              <h5 className="product_type__title">
                {labelWithHints(
                  t('profile.food_safety.kernel.heading'),
                  t('shared.profile_kernel_food_safety_tooltip'),
                )}
              </h5>
              <Field name="safety_document" validate={validateOptionalPortalDocuments}>
                {({ input, meta }) => (
                  <ChooseFile
                    className="padding-b-16px food-safety__divider"
                    fileName={input.value?.name}
                    fileUrl={initialValues?.safety_document?.url}
                    label={t('profile.food_safety.fields.safety_documents')}
                    hint={t('profile.file.upload')}
                    onChange={(file) => {
                      input.onChange(file);
                    }}
                    disableDelete={disableDelete}
                    onDelete={canDeleteAttachemt ? () => handleDelete(input) : () => {}}
                    touched={meta?.touched}
                    errorMessage={meta?.error || meta?.submitError}
                  />
                )}
              </Field>
              <Field name="safety_process_diagram" validate={validateOptionalPortalDocuments}>
                {({ input, meta }) => (
                  <ChooseFile
                    className="padding-y-16px food-safety__divider"
                    fileName={input.value?.name}
                    fileUrl={initialValues?.safety_process_diagram?.url}
                    label={t('profile.food_safety.fields.safety_process_diagram')}
                    hint={t('profile.file.upload')}
                    onChange={(file) => {
                      input.onChange(file);
                    }}
                    disableDelete={disableDelete}
                    onDelete={canDeleteAttachemt ? () => handleDelete(input) : () => {}}
                    touched={meta?.touched}
                    errorMessage={meta?.error || meta?.submitError}
                  />
                )}
              </Field>
              <Field name="safety_data_sheet" validate={validateOptionalPortalDocuments}>
                {({ input, meta }) => (
                  <ChooseFile
                    className="padding-y-16px food-safety__divider"
                    fileName={input.value?.name}
                    fileUrl={initialValues?.safety_data_sheet?.url}
                    label={t('profile.food_safety.fields.safety_data_sheet')}
                    hint={t('profile.file.upload')}
                    onChange={(file) => {
                      input.onChange(file);
                    }}
                    disableDelete={disableDelete}
                    onDelete={canDeleteAttachemt ? () => handleDelete(input) : () => {}}
                    touched={meta?.touched}
                    errorMessage={meta?.error || meta?.submitError}
                  />
                )}
              </Field>
              <Field name="total_diet_study" validate={validateOptionalPortalDocuments}>
                {({ input, meta }) => (
                  <ChooseFile
                    className="padding-y-16px food-safety__divider"
                    fileName={input.value?.name}
                    fileUrl={initialValues?.total_diet_study?.url}
                    label={t('profile.food_safety.fields.diet_study')}
                    hint={t('profile.file.upload')}
                    onChange={(file) => {
                      input.onChange(file);
                    }}
                    disableDelete={disableDelete}
                    onDelete={canDeleteAttachemt ? () => handleDelete(input) : () => {}}
                    touched={meta?.touched}
                    errorMessage={meta?.error || meta?.submitError}
                  />
                )}
              </Field>
              <Field name="regulatory_approvals" validate={validateOptionalPortalDocuments}>
                {({ input, meta }) => (
                  <ChooseFile
                    className="padding-t-16px padding-b-32px"
                    fileName={input.value?.name}
                    fileUrl={initialValues?.regulatory_approvals?.url}
                    label={t('profile.food_safety.fields.regulatory_approval')}
                    hint={t('profile.file.upload')}
                    onChange={(file) => {
                      input.onChange(file);
                    }}
                    disableDelete={disableDelete}
                    onDelete={canDeleteAttachemt ? () => handleDelete(input) : () => {}}
                    touched={meta?.touched}
                    errorMessage={meta?.error || meta?.submitError}
                  />
                )}
              </Field>
            </div>
            <h5 className="product_type__title">
              {labelWithHints(
                t('profile.food_safety.dnis.heading'),
                t('shared.profile_dnis_food_safety_tooltip'),
              )}
            </h5>
            <div className="padding-x-12px">
              <Field name="ppecb_company_certificate" validate={validateOptionalPortalDocuments}>
                {({ input, meta }) => (
                  <ChooseFile
                    className="padding-y-16px food-safety__divider"
                    fileName={input.value?.name}
                    fileUrl={initialValues?.ppecb_company_certificate?.url}
                    label={t('profile.food_safety.fields.ppecb_company_certificate')}
                    hint={t('profile.file.upload')}
                    onChange={(file) => {
                      input.onChange(file);
                    }}
                    disableDelete={disableDeletePpecb}
                    onDelete={canDeletePpecb ? () => handleDelete(input) : () => {}}
                    touched={meta?.touched}
                    errorMessage={meta?.error || meta?.submitError}
                  />
                )}
              </Field>
              <Field
                name="global_gap_company_certificate"
                validate={validateOptionalPortalDocuments}
              >
                {({ input, meta }) => (
                  <ChooseFile
                    className="padding-y-16px food-safety__divider"
                    fileName={input.value?.name}
                    fileUrl={initialValues?.global_gap_company_certificate?.url}
                    label={t('profile.food_safety.fields.global_gap_company_certificate')}
                    hint={t('profile.file.upload')}
                    onChange={(file) => {
                      input.onChange(file);
                    }}
                    disableDelete={disableDeleteGlobalGap}
                    onDelete={canDeleteGlobalGap ? () => handleDelete(input) : () => {}}
                    touched={meta?.touched}
                    errorMessage={meta?.error || meta?.submitError}
                  />
                )}
              </Field>
              <Field name="gacc_company_certificate" validate={validateOptionalPortalDocuments}>
                {({ input, meta }) => (
                  <ChooseFile
                    className="padding-y-16px food-safety__divider"
                    fileName={input.value?.name}
                    fileUrl={initialValues?.gacc_company_certificate?.url}
                    label={t('profile.food_safety.fields.gacc_company_certificate')}
                    hint={t('profile.file.upload')}
                    onChange={(file) => {
                      input.onChange(file);
                    }}
                    disableDelete={disableDeleteGacc}
                    onDelete={canDeleteGacc ? () => handleDelete(input) : () => {}}
                    touched={meta?.touched}
                    errorMessage={meta?.error || meta?.submitError}
                  />
                )}
              </Field>
              <Field name="mrl_company_results" validate={validateOptionalPortalDocuments}>
                {({ input, meta }) => (
                  <ChooseFile
                    className="padding-y-16px food-safety__divider"
                    fileName={input.value?.name}
                    fileUrl={initialValues?.mrl_company_results?.url}
                    label={t('profile.food_safety.fields.mrl_company_results')}
                    hint={t('profile.file.upload')}
                    onChange={(file) => {
                      input.onChange(file);
                    }}
                    disableDelete={disableDeleteMrl}
                    onDelete={canDeleteMrl ? () => handleDelete(input) : () => {}}
                    touched={meta?.touched}
                    errorMessage={meta?.error || meta?.submitError}
                  />
                )}
              </Field>
              <Field name="esg_company_certificate" validate={validateOptionalPortalDocuments}>
                {({ input, meta }) => (
                  <ChooseFile
                    className="padding-y-16px food-safety__divider"
                    fileName={input.value?.name}
                    fileUrl={initialValues?.esg_company_certificate?.url}
                    label={t('profile.food_safety.fields.esg_company_certificate')}
                    hint={t('profile.file.upload')}
                    onChange={(file) => {
                      input.onChange(file);
                    }}
                    disableDelete={disableDeleteEsg}
                    onDelete={canDeleteEsg ? () => handleDelete(input) : () => {}}
                    touched={meta?.touched}
                    errorMessage={meta?.error || meta?.submitError}
                  />
                )}
              </Field>
            </div>

            <h5 className="product_type__title">
              {labelWithHints(
                t('profile.food_safety.organic.heading'),
                t('shared.profile_organic_food_safety_tooltip'),
              )}
            </h5>
            <div className="padding-x-12px">
              <Field name="organic_certificate" validate={validateOptionalPortalDocuments}>
                {({ input, meta }) => (
                  <ChooseFile
                    fileName={input.value?.name}
                    fileUrl={initialValues?.organic_certificate?.url}
                    label={t('profile.food_safety.fields.organic_certificate')}
                    hint={t('profile.file.upload')}
                    onChange={(file) => {
                      input.onChange(file);
                    }}
                    disableDelete={disableDeleteOrganic}
                    onDelete={canDeleteOrganic ? () => handleDelete(input) : () => {}}
                    touched={meta?.touched}
                    errorMessage={meta?.error || meta?.submitError}
                  />
                )}
              </Field>
            </div>

            <Button
              label={t('profile.food_safety.save_button')}
              className="food-safety__submit-btn margin-t-24px margin-b-32px margin-l-12px"
              submitType="submit"
              type="success"
              disabled={submitting}
            />

            {isEditable && !isFormSaved && (
              <Button
                className="food-safety____draft-btn margin-t-24px margin-b-32px margin-l-8px"
                label="Save as draft"
                onClick={() => saveAsDraft(values)}
                type="default"
                disabled={submitting}
              />
            )}
          </fieldset>
        </form>
      )}
    />
  );
}

FoodSafetyForm.defaultProps = {
  companyId: null,
  toastRef: null,
  isEditable: false,
};

FoodSafetyForm.propTypes = {
  companyId: PropTypes.number,
  toastRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  isEditable: PropTypes.bool,
  userDetails: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
  }).isRequired,
};

export default FoodSafetyForm;
